import React from "react";
import { FaStar } from "react-icons/fa";
import { ProblemData } from "../Data/Problems";

const Problems = () => {
  return (
    <div
      className="Lato py-24 px-[120px] flex bg-[#4831D4]  h-[1164px] justify-center min-w-[1440px]  flex-col items-center gap-12"
      id="solution"
    >
      {/* <div className="w-[1200px] h-[1052px] flex flex-col gap-12"> */}
      <div className="w-[1200px] flex flex-col gap-4 items-center text-white mb-12">
        {/* heading */}
        <h6 className="text-xl font-bold leading-5 text-[#DDD5FC] capitalize">
          SOLUTION
        </h6>
        <h3 className="font-bold text-[40px] leading-[64px] ">
          Why Choose Flash for Global Logistics Management
        </h3>
      </div>

      <div className=" flex flex-wrap gap-6 w-[1200px]">
        {ProblemData.map((data) => (
          <div
            key={data.id}
            className="flex flex-col w-[588px] py-6 px-4 rounded-2xl gap-4 text-white bg-gradient-to-b from-[rgba(255,255,255,0.25)] to-[rgba(99,73,255,0.25)] drop-shadow-[0_0_0_rgba(0,0,0,0.25)]"
          >
            <h3 className="font-bold text-2xl flex gap-[10px] items-center">
              {data.heading} <FaStar />
            </h3>
            <p className="text-lg leading-[29px] text-[#D0D5DD] text-left">
              {data.content}
            </p>
          </div>
        ))}
      </div>
      {/* </div> */}
    </div>
  );
};

export default Problems;
