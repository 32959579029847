import React from "react";
import { HashLink as Link } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";

const Hero = () => {
  return (
    <div className="Lato w-[1440px] py-24 px-[120px] flex flex-col  " id="hero">
      <div className="flex gap-[21px] mt-[100px]  justify-between">
        {/* heading */}
        <div className="flex flex-col gap-7 w-[712px]">
          <h1 className="text-[64px] font-bold text-[#101828] leading-[80px]">
            Global Supply Chain Management Platform
          </h1>
          <h6 className="text-xl font-normal text-[#344054]">
            Seamless, Efficient, and Cost-Effective Global Logistics
          </h6>
          {/* button group */}
          <div className="flex gap-6">
            <Link to="/contact">
              <button className="w-[172px] py-4 px-6 rounded-lg bg-[#4831D4] text-[16px] font-bold text-white">
                Join The Waitlist
              </button>
            </Link>
            <a
              href="https://calendly.com/parikshit-bhanushali/"
              target="_blank"
            >
              <button className="w-[172px] py-4 px-6 rounded-lg text-[16px] font-bold bg-[#F9F7FD]">
                Talk To Founders
              </button>
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <div className="">
            <img src="/assets/Box.svg" alt="" />
          </div>
          <div className="flex flex-wrap gap-7 w-[432px] justify-center items-center">
            <div className="w-[64px] h-[64px]">
              <img src="/assets/Partners/DHL.svg" alt="DHL" />
            </div>
            <div className="w-[64px] h-[64px]">
              <img src="/assets/Partners/partner2.svg" alt="partner" />
            </div>
            <div className="w-[64px] h-[64px]">
              <img src="/assets/Partners/Delivery.svg" alt="delivery" />
            </div>
            <div className="w-[64px] h-[64px]">
              <img src="/assets/Partners/UPS.svg" alt="ups" />
            </div>
            <div className="w-[64px] h-[64px]">
              <img src="/assets/Partners/FedEX.svg" alt="fedex" />
            </div>
            <div className="flex items-center">
              <h3 className="font-bold text-sm ">25+ More</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="flex  Inter -mt-32">
        <h2 className="font-bold text-4xl  leading-[56px]">
          Heavy on savings! <br /> Light on costs!
        </h2>
      </div>
    </div>
  );
};

export default Hero;

<div className="flex">
  {/* partners */}
  <div className="flex flex-wrap gap-[39px] w-[300px]">
    <div className="w-[88px] h-[88px]">
      <img src="/assets/Partners/DHL.svg" alt="DHL" />
    </div>
    <div className="w-[88px] h-[88px]">
      <img src="/assets/Partners/partner2.svg" alt="partner" />
    </div>
    <div className="w-[88px] h-[88px]">
      <img src="/assets/Partners/Delivery.svg" alt="delivery" />
    </div>
    <div className="w-[88px] h-[88px]">
      <img src="/assets/Partners/UPS.svg" alt="ups" />
    </div>
    <div className="w-[88px] h-[88px]">
      <img src="/assets/Partners/FedEX.svg" alt="fedex" />
    </div>
    <div className="w-[172px] flex items-center">
      <h3 className="font-bold text-4xl text-[#4831D4]">25+ More</h3>
    </div>
  </div>

  {/* box */}
  <div className="ml-[147px] mt-16">
    <img src="/assets/Box.svg" alt="" />
  </div>

  {/* text */}
</div>;
