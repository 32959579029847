export const companies = [
  {
    id: 0,
    img: "/assets/companies/company1.svg",
  },
  {
    id: 1,
    img: "/assets/companies/company2.svg",
  },
  {
    id: 2,
    img: "/assets/companies/company3.svg",
  },
  {
    id: 3,
    img: "/assets/companies/company4.svg",
  },
  {
    id: 4,
    img: "/assets/companies/company5.svg",
  },
  {
    id: 5,
    img: "/assets/companies/company6.svg",
  },
];
