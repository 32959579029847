export const ProblemData = [
  {
    id: 0,
    heading: "Save 30% on Logistics",
    content:
      "By comparing rates across 25+ carriers, Flash ensures competitive pricing, helping you reduce shipping costs",
  },
  {
    id: 1,
    heading: "5X Improved Efficiency",
    content:
      "Our platform is crafted to maximize savings, letting you select the most cost-effective and efficient options without manual comparisons",
  },
  {
    id: 2,
    heading: "No Order Minimums",
    content:
      "Flash offers discounted rates with no minimum order requirements, giving businesses the flexibility to ship as needed.",
  },
  {
    id: 3,
    heading: "Fastest Carrier Discovery",
    content:
      "With Flash, finding the quickest carrier becomes effortless, minimizing shipping time and improving customer satisfaction.",
  },
  {
    id: 4,
    heading: "24 / 7 Available Assistance",
    content:
      "Our dedicated customer support team is available around the clock to resolve any logistics issues that arise.",
  },
  {
    id: 5,
    heading: "Full Cost Transparency",
    content:
      "Flash provides clear, upfront pricing with transparent tracking and analytics, allowing you to plan confidently.",
  },
  {
    id: 6,
    heading: "Data-Driven Recommendations",
    content:
      "Leveraging data, our recommendation system guides you in choosing the most efficient options based on your shipping history.",
  },
  {
    id: 7,
    heading: "Flexible Shipping Rules",
    content:
      "Create and customize rules that automate logistics based on your unique needs, saving time and reducing manual tasks.",
  },
];
