import React from "react";

const Testimonials = () => {
  return (
    <div className="Lato py-24 px-[120px] flex flex-col  min-w-[1440px] gap-12 justify-center items-center">
      <div className="flex flex-col gap-4 items-center text-white mb-12">
        {/* heading */}
        <h6 className="text-xl font-bold leading-5 text-[#4831D4] capitalize">
          TESTIMONIALS
        </h6>
        <h3 className="font-bold text-[40px] leading-[64px] text-[#101828]">
          What Our Customers Are Saying
        </h3>
      </div>

      <div className="w-[1200px] flex gap-12 justify-center items-center">
        <div
          className="bg-white w-[576px] rounded-[5px] border-[1px] border-[#E2E8F0] px-12 pt-12 pb-6 flex flex-col gap-6"
          style={{
            boxShadow:
              "0px 0px 6px 0px rgba(0, 0, 0, 0.07), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="flex flex-col gap-4">
            <div className="font-bold text-4xl">⭐⭐⭐⭐⭐</div>
            <h6 className="text-2xl text-[#0F172A] leading-[38px]">
              “Flash has been super helpful for us to get the Cheapest Carrier
              without us maintaining any account with all these carriers saving
              us time. Flash's UI is super simple and very informative to give
              us real time tracking of the shipments. It has really helped our
              small business scale.”
            </h6>
          </div>
          <div className="flex gap-4 items-center">
            <div>
              <img src="/assets/companies/SoulUrns.svg" alt="" />
            </div>
            <div>
              <a href="https://www.soulurns.us/">
                <h2 className="text-2xl font-bold text-[#0F172A]">
                  <span className="underline">Soul Urns</span> - Mr. Fazlur Khan
                </h2>
              </a>
              <h6 className="text-[16px] text-[#475569] font-bold">
                D2C Brand <strong>₹10 Crore</strong> ARR{" "}
              </h6>
            </div>
          </div>
        </div>
        <div
          className="bg-white w-[576px] rounded-[5px] border-[1px] border-[#E2E8F0] px-12 pt-12 pb-6 flex flex-col gap-6"
          style={{
            boxShadow:
              "0px 0px 6px 0px rgba(0, 0, 0, 0.07), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="flex flex-col gap-4">
            <div className="font-bold text-4xl">⭐⭐⭐⭐⭐</div>
            <h6 className="text-2xl text-[#0F172A] leading-[38px]">
              “Flash's order management allows us to manage all our
              international order at a single place making it very easy. We like
              their 24/7 customer support and prompt responses to our teams
              queries. Flash's Shipping rule engine allowed us to automate our
              tasks saving us time to focus on business growth.”
            </h6>
          </div>
          <div className="flex gap-4 items-center">
            <div>
              <img src="/assets/companies/Cbazar.svg" alt="" />
            </div>
            <div>
              <a href="https://www.cbazaar.com/in">
                <h2 className="text-2xl font-bold text-[#0F172A]">
                  <span className="underline">CBazaar</span> - Mr. Prakash A{" "}
                </h2>
              </a>
              <h6 className="text-[16px] text-[#475569] font-bold">
                D2C Brand <strong>₹30 Crore</strong> ARR{" "}
              </h6>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default Testimonials;
