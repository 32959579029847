import React from "react";
import { CiGlobe } from "react-icons/ci";
import { MdOutlineMailOutline } from "react-icons/md";
import { TbPhoneCall } from "react-icons/tb";
import { HashLink as Link } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";
import whiteLogo from "../assets/whiteLogo.svg";

const Footer = () => {
  return (
    <>
      <div className="Lato py-24 px-[120px] flex flex-col  min-w-[1440px] gap-8 justify-center items-center bg-[#4831D4] text-white">
        <h3 className="text-[40px] font-bold leading-[56px]">
          Supercharge Your Exports Now
        </h3>
        <div className="flex gap-6">
          <Link to="/contact/#contact">
            <button className="w-[172px] py-4 px-6 rounded-lg  text-[16px] text-black font-bold bg-white">
              Join The Waitlist
            </button>
          </Link>
          <a href="https://calendly.com/parikshit-bhanushali/" target="_blank">
            <button className="w-[172px] py-4 px-6 rounded-lg text-[16px] font-bold bg-[#3623B6]">
              Talk To Founders
            </button>
          </a>
        </div>
        <h1 className="text-[#F9F7FD] text-[49px] font-extrabold flex gap-2 items-center tracking-wider">
          GET SET FLASH{" "}
          <span>
            {" "}
            <img src={whiteLogo} alt="" />
          </span>
        </h1>
      </div>

      <div className="Lato pt-16 pb-8 px-[120px] flex flex-col   min-w-[1440px] gap-8   bg-[#4831D4] text-white">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-3">
            <h2 className="text-[56px] font-bold flex gap-[10px] items-center">
              <span>
                <img src={whiteLogo} alt="" />
              </span>
              FLASH
            </h2>
            <div>
              <h6 className="text-sm text-[#EAECF0]">
                Flashport Technologies Private Limited
                <br /> Made with ❤️ In India for the World
              </h6>
            </div>
          </div>

          <div className="text-sm text-[#EAECF0] flex gap-[34px] justify-end items-center mt-16">
            <h5 className="flex gap-3">
              <TbPhoneCall className="w-6 h-6 text-[white]" /> +917045063838
            </h5>
            <h5 className="flex gap-3">
              <MdOutlineMailOutline className="w-6 h-6 text-[white]" />
              parikshit@goflash.in
            </h5>

            <h5 className="flex gap-3">
              <img
                src="/assets/officeBuilding.svg"
                alt=""
                className="w-6 h-6"
              />
              BTM Layout, Bangalore 560068, Karnataka, India
            </h5>
          </div>
        </div>
        <div className="flex justify-between text-[#E2E8F0] text-[16px]">
          <h3>© 2024 Flashport Technologies. All rights reserved</h3>
          <ul className="flex gap-8">
            <li>Terms</li>
            <li>Privacy</li>
            <Link to="/contact/#contact">
              <li>Contact</li>
            </Link>
            <li className="flex gap-2 items-center">
              <CiGlobe /> EN
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
