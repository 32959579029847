export const FAQData = [
  {
    id: 0,
    heading: "How does the Platform work?",
    detail:
      " It's pretty simple! Signup and complete your KYC which takes less than 5 minutes. Then link your sales channels where you get orders from like Amazon, Ebay, Walmart, etc. We then pull all your orders and bring them at one place in our orders tab. You can select a particular order and click on Ship Now to compare rates across 25+ carriers and book your shipment.",
  },
  {
    id: 1,
    heading: "How does the pricing work?",
    detail:
      " The platform is free to use to any exporter of any size exporting to any country. You just pay us the shipment charges at the time of booking the shipment and these are highly discounted rates from the carriers that we have agreed on your behalf. The rates will be the best irrespective if you ship a single order or bulk orders. ",
  },
  {
    id: 2,
    heading: "How does Shipping Rule work?",
    detail:
      " We built shipping rule feature for the main reason which is to optimize for your Global logistics and Automate your shipment bookings as per order. You can go to Shipping rules tab in our platform where you can see 2 types of rules. 1st allows you to auto select the type of carrier to be assigned to each order basis Cheapest Cost or Fastest Time or a combination of both. 2nd allows you to setup Advance shipping rules basis destination country, zip code, product types, and hundreds of other parameters.",
  },
];
