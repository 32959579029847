import React from "react";

const HowItWorks = () => {
  return (
    <div
      className="Lato py-24 px-[120px] min-w-[1440px] flex justify-center flex-col items-center gap-12"
      id="how"
    >
      <div className="flex flex-col gap-4 items-center">
        <h6 className="text-xl font-bold leading-5 text-[#4831D4] capitalize">
          HOW IT WORKS
        </h6>
        <h3 className="font-bold text-[40px] leading-[64px] text-[#101828]">
          Effortless Shipping in 3 Steps
        </h3>
      </div>

      <div className="w-[1200px] h-[892px] flex rounded-3xl overflow-hidden justify-center items-center">
        <div className="w-[600px]">
          <img src="/assets/manImage.svg" alt="man" width={600} height={892} />
        </div>

        <div className="w-[600px] flex flex-col justify-center items-center bg-[#4831D4] py-24 px-3 h-full">
          <div className="flex flex-col w-[384px] p-6 rounded-2xl justify-center items-center gap-4 text-white bg-gradient-to-b from-[rgba(255,255,255,0.25)] to-[rgba(99,73,255,0.25)] drop-shadow-[0_0_0_rgba(0,0,0,0.25)]">
            <h3 className="font-[900] text-[32px]">01</h3>
            <h3 className="font-extrabold text-2xl">Complete KYC</h3>
            <p className="text-lg leading-[29px] text-[#D0D5DD] text-center">
              Activate your account in just 5 minutes with a quick, simple KYC
              process.
            </p>
          </div>

          {/* <!-- Dashed Line --> */}
          <div className="w-full flex justify-center">
            <div className="w-[2px] h-11 bg-transparent border-l-2 border-dashed border-white"></div>
          </div>

          <div className="flex flex-col w-[384px] p-6 rounded-2xl justify-center items-center gap-4 text-white bg-gradient-to-b from-[rgba(255,255,255,0.25)] to-[rgba(99,73,255,0.25)] drop-shadow-[0_0_0_rgba(0,0,0,0.25)]">
            <h3 className="font-[900] text-[32px]">02</h3>
            <h3 className="font-extrabold text-2xl">Link Sales Channel</h3>
            <p className="text-lg leading-[29px] text-[#D0D5DD] text-center">
              Connect 30+ sales channels and manage all international orders
              from one place.
            </p>
          </div>

          {/* <!-- Dashed Line --> */}
          <div className="w-full flex justify-center">
            <div className="w-[2px] h-11 bg-transparent border-l-2 border-dashed border-white"></div>
          </div>

          <div className="flex flex-col w-[384px] p-6 rounded-2xl justify-center items-center gap-4 text-white bg-gradient-to-b from-[rgba(255,255,255,0.25)] to-[rgba(99,73,255,0.25)] drop-shadow-[0_0_0_rgba(0,0,0,0.25)]">
            <h3 className="font-[900] text-[32px]">03</h3>
            <h3 className="font-extrabold text-2xl">Compare Rates and Ship</h3>
            <p className="text-lg leading-[29px] text-[#D0D5DD] text-center">
              Access 25+ carriers without individual accounts or complex
              negotiations.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
