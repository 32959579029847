import React from "react";

const SuccessPopUp = ({ setSuccess }) => {
  return (
    <div
      className="absolute w-[600px] Lato flex justify-between gap-3 py-3 px-3 items-center right-8 top-[132px] rounded-xl"
      style={{
        boxShadow: `
            0px 3px 1px -1px rgba(26, 26, 26, 0.07),
            0px 1px 0px 0px rgba(204, 204, 204, 0.5),
            0px -1px 0px 0px rgba(0, 0, 0, 0.17),
            -1px 0px 0px 0px rgba(0, 0, 0, 0.13),
            1px 0px 0px 0px rgba(0, 0, 0, 0.13)
        `,
      }}
    >
      <div className=" gap-3 flex items-center">
        <img src="/assets/Success.svg" alt="" />
        <h3 className="text-sm font-semibold tracking-wider text-[#303030]">
          Thank you for your time! Our executive will contact you in some time
        </h3>
      </div>
      <div className="cursor-pointer" onClick={() => setSuccess(false)}>
        <img src="/assets/cut.svg" alt="" />
      </div>
    </div>
  );
};

export default SuccessPopUp;
