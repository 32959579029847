import React from "react";
import Hero from "../components/Hero";
import CompanyMarquee from "../components/CompanyMarquee";
import HowItWorks from "../components/HowItWorks";
import Features from "../components/Features";
import Product from "../components/Product";
import GlobalReach from "../components/GlobalReach";
import Problems from "../components/Problems";
import Testimonials from "../components/Testimonials";
import FAQ from "../components/FAQ";

const Home = () => {
  return (
    <>
      <Hero />
      <CompanyMarquee />
      <HowItWorks />
      <Features />
      <Product />
      <GlobalReach />
      <Problems />
      <Testimonials />
      <FAQ />
    </>
  );
};

export default Home;
