import React from "react";
import { companies } from "../Data/TrustedCompanies";
import Marquee from "react-fast-marquee";

const CompanyMarquee = () => {
  return (
    <div className="Lato py-16 px-[120px] flex justify-center items-center flex-col gap-8 w-[1440px]">
      <h3 className="text-xl text-[#344054]">
        Trusted by teams from leading companies
      </h3>
      <Marquee speed={20} direction="right" gradient={true} gradientWidth={10}>
        <div className="flex gap-[197px] ">
          {companies.map((data) => (
            <div key={data.id} className="last:mr-[197px]">
              <img src={data.img} alt="image" />
            </div>
          ))}
        </div>
      </Marquee>
    </div>
  );
};

export default CompanyMarquee;
