export const ProductData = [
  {
    id: 0,
    img1: "/assets/product/image1.jpg",
    img2: "/assets/product/image2.jpg",
    heading: "In Depth In-Sights and Real time Tracking",
    data: "Get in depth insights on every carrier and make the best decision instantly. With our comprehensive Dashboard you can now Track all your shipments in real time on the World Map and take prompt actions.",
  },
  {
    id: 1,
    img1: "/assets/product/image3.svg",
    heading: "Global Shipping Orchestration",
    data: "Automate all your shipments by creating custom rules and assign specific carriers that meets your requirements. With our advance shipping rules exporters can save dozens of hours on manual shipment creation.",
  },
  {
    id: 2,
    img1: "/assets/product/image4.svg",
    heading: "Manage all your Orders in single place",
    data: "With Flash you can link and pull order across different channels where you sell making it very easy for you to manage orders. Use our Ship Now, feature to ship your orders in One Click.",
  },
];
