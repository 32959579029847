import React, { useEffect, useState } from "react";
import { ProductData } from "../Data/WhyFlash";

const Product = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [userClicked, setUserClicked] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % ProductData.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userClicked) {
      setUserClicked(false);
      const timer = setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % ProductData.length);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [activeIndex]);

  const handleHeadingClick = (index) => {
    setUserClicked(true);
    setActiveIndex(index);
  };
  return (
    <div
      className="Lato py-24  flex flex-col bg-[#4831D4] min-w-[1440px] h-[830px] justify-center items-center"
      id="product"
    >
      {/* <div className="w-full flex flex-col h-[830px] px-20 gap-20"> */}
      <div className="flex flex-col gap-4 py-9 items-center text-white">
        {/* heading */}
        <h6 className="text-xl font-bold leading-5 text-[#DDD5FC] capitalize">
          PRODUCT
        </h6>
        <h3 className="font-bold text-[40px] leading-[64px] ">
          Why Choose Flash for Global Logistics Management
        </h3>
      </div>
      {/* content */}
      <div className="w-[1280px] flex h-[460px] ">
        <div className="w-[640px] flex justify-center items-center flex-col">
          <div className="flex flex-col gap-6">
            <div className="rounded-lg">
              <img
                src={ProductData[activeIndex].img1}
                alt="error"
                className="rounded-lg"
              />
            </div>
            {ProductData[activeIndex].img2 && (
              <div className="rounded-lg">
                <img
                  src={ProductData[activeIndex].img2}
                  alt="error"
                  className="rounded-lg"
                />
              </div>
            )}
          </div>
        </div>

        <div className="h-[178px] w-1/2 py-[79px] pl-[26px] flex flex-col gap-6 text-white">
          {ProductData.map((section, index) => (
            <div
              key={index}
              className={`py-2 pl-12 flex flex-col gap-6 cursor-pointer ${
                activeIndex === index
                  ? "border-l-[3px] border-white"
                  : "border-l-[3px] border-[#9582F2]"
              }`}
              onClick={() => handleHeadingClick(index)}
            >
              <h3 className="font-bold text-xl">{section.heading}</h3>
              {activeIndex === index && (
                <p className="text-lg">{section.data}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Product;
