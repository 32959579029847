import React from "react";

const Features = () => {
  return (
    <div
      className="Lato py-24 px-[120px] h-[900px] flex flex-col bg-[#4831D4] min-w-[1440px] gap-12 justify-center items-center"
      id="feature"
    >
      <div className="flex flex-col gap-4 items-center text-white">
        <h6 className="text-xl font-bold leading-5 text-[#DDD5FC] capitalize">
          FEATURES
        </h6>
        <h3 className="font-bold text-[40px] leading-[64px] ">
          Streamlining Your Shipping
        </h3>
        <h6 className="text-xl text-[#D0D5DD]">
          Maximize efficiency with Flash’s advanced features that make global
          logistics simpler and more cost-effective
        </h6>
      </div>

      <div className="w-[1200px] flex flex-wrap gap-6 justify-center items-center">
        <div className="flex flex-col w-[384px] py-6 px-4 rounded-2xl justify-center items-center gap-4 text-white bg-gradient-to-b from-[rgba(255,255,255,0.25)] to-[rgba(99,73,255,0.25)] drop-shadow-[0_0_0_rgba(0,0,0,0.25)]">
          <h3 className="font-[900] text-[32px]">01</h3>
          <h3 className="font-extrabold text-2xl">Order Management</h3>
          <p className="text-lg leading-[29px] text-[#D0D5DD] text-center">
            "Seamlessly handle all global orders from one platform."
          </p>
        </div>
        <div className="flex flex-col w-[384px] py-6 px-4 rounded-2xl justify-center items-center gap-4 text-white bg-gradient-to-b from-[rgba(255,255,255,0.25)] to-[rgba(99,73,255,0.25)] drop-shadow-[0_0_0_rgba(0,0,0,0.25)]">
          <h3 className="font-[900] text-[32px]">02</h3>
          <h3 className="font-extrabold text-2xl">
            Cheapest and Fastest Shipping
          </h3>
          <p className="text-lg leading-[29px] text-[#D0D5DD] text-center">
            "Access the most cost-effective and quickest shipping options to fit
            your needs."
          </p>
        </div>
        <div className="flex flex-col w-[384px] py-6 px-4 rounded-2xl justify-center items-center gap-4 text-white bg-gradient-to-b from-[rgba(255,255,255,0.25)] to-[rgba(99,73,255,0.25)] drop-shadow-[0_0_0_rgba(0,0,0,0.25)]">
          <h3 className="font-[900] text-[32px]">03</h3>
          <h3 className="font-extrabold text-2xl">Automated Shipping Rules</h3>
          <p className="text-lg leading-[29px] text-[#D0D5DD] text-center">
            "Build and apply custom shipping rules to automate your logistics
            processes."
          </p>
        </div>

        <div className="flex flex-col w-[384px] py-6 px-4 rounded-2xl justify-center items-center gap-4 text-white bg-gradient-to-b from-[rgba(255,255,255,0.25)] to-[rgba(99,73,255,0.25)] drop-shadow-[0_0_0_rgba(0,0,0,0.25)]">
          <h3 className="font-[900] text-[32px]">04</h3>
          <h3 className="font-extrabold text-2xl">Workflow Automation</h3>
          <p className="text-lg leading-[29px] text-[#D0D5DD] text-center">
            "Automate repetitive tasks and standardize workflows to improve
            operational flow."
          </p>
        </div>

        <div className="flex flex-col w-[384px] py-6 px-4 rounded-2xl justify-center items-center gap-4 text-white bg-gradient-to-b from-[rgba(255,255,255,0.25)] to-[rgba(99,73,255,0.25)] drop-shadow-[0_0_0_rgba(0,0,0,0.25)]">
          <h3 className="font-[900] text-[32px]">05</h3>
          <h3 className="font-extrabold text-2xl">Customs Compliance</h3>
          <p className="text-lg leading-[29px] text-[#D0D5DD] text-center">
            "Easily manage customs requirements to prevent delays and ensure
            smooth delivery."
          </p>
        </div>
      </div>
    </div>
  );
};

export default Features;
