import React from "react";

const GlobalReach = () => {
  return (
    <div className="Lato py-24 px-[120px] flex flex-col  min-w-[1440px] gap-12 justify-center items-center">
      <div className="flex flex-col gap-4 items-center text-white mb-12 ">
        {/* heading */}
        <h6 className="text-xl font-bold leading-5 text-[#4831D4] capitalize">
          GLOBAL REACH
        </h6>
        <h3 className="font-bold text-[40px] leading-[64px] text-[#101828]">
          Flash supports exports to 200+ countries
        </h3>
      </div>

      <div className="w-[1200px]">
        <img src="/assets/Map.svg" alt="" />
      </div>
    </div>
  );
};

export default GlobalReach;
