import React, { useRef, useState } from "react";
import SuccessPopUp from "./SuccessPopUp";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const [success, setSuccess] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_0q9lxfp",
        "template_626jftj",
        form.current,
        "_ASHUVaooGOp-XAsX"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setSuccess(true);
          window.location = window.location + "#contact"; // Display success message
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <>
      {success && <SuccessPopUp setSuccess={setSuccess} />}
      <div
        className="Lato py-14 px-[120px] flex flex-col min-w-[1440px] justify-center items-center gap-12"
        id="contact"
      >
        <div className="w-[1200px] flex gap-1 flex-col mt-[100px]">
          <h4 className="text-2xl">Get Started with Flash</h4>
          <h1 className="text-[50px] font-extrabold text-[#101828]">
            Get in touch with us. We're here to assist you.
          </h1>
        </div>

        <form
          className="flex w-[1200px] justify-center items-center"
          ref={form}
          onSubmit={sendEmail}
        >
          <div className="flex gap-6 flex-wrap items-center">
            <div className="flex w-[384px]">
              <input
                name="name"
                type="text"
                placeholder="Your Name"
                className="border-[1px] border-[#EAECF0] rounded-xl w-full py-4 px-6 text-[#667085] text-[16px]"
                required
              />
            </div>

            <div className="flex w-[384px]">
              <input
                name="email"
                type="email"
                placeholder="Email Address"
                className="border-[1px] border-[#EAECF0] rounded-xl py-4 px-6 text-[#667085] text-[16px] w-full"
                required
              />
            </div>

            <div className="flex w-[384px]">
              <input
                name="phoneNumber"
                type="tel"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="Phone Number"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                required
                className="border-[1px] border-[#EAECF0] rounded-xl py-4 px-6 text-[#667085] text-[16px] w-full"
              />
            </div>

            <div className="flex w-[588px]">
              <input
                name="companyName"
                type="text"
                placeholder="Company Name"
                required
                className="border-[1px] border-[#EAECF0] rounded-xl w-full py-4 px-6 text-[#667085] text-[16px]"
              />
            </div>

            <div className="flex w-[588px]">
              <input
                name="ShipmentCount"
                type="tel"
                inputMode="numeric"
                pattern="[0-9]*"
                placeholder="Number of Shipments"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                required
                className="border-[1px] border-[#EAECF0] rounded-xl py-4 px-6 text-[#667085] text-[16px] w-full"
              />
            </div>

            <div className="flex w-[1200px] h-[200px]">
              <textarea
                name="message"
                placeholder="Message"
                required
                className="border-[1px] border-[#EAECF0] rounded-xl w-full h-full py-4 px-6 text-[#667085] text-[16px] resize-none"
              />
            </div>

            <button
              type="submit"
              className="mt-6 w-[198px] py-4 px-6 bg-[#4831D4] text-[#F9F7FD] text-[16px] font-bold rounded-lg"
            >
              Leave us a message
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
