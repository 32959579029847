import React from "react";
import { IoIosArrowDown } from "react-icons/io";
import { FAQData } from "../Data/FAQ";

const FAQ = () => {
  return (
    <div className="Lato py-24 px-[120px] flex flex-col  min-w-[1440px] gap-12 justify-center items-center">
      <div className="flex flex-col gap-4 items-center text-white mb-12">
        {/* heading */}
        <h6 className="text-xl font-bold leading-5 text-[#4831D4] capitalize">
          FAQ'S
        </h6>
        <h3 className="font-bold text-[40px] leading-[64px] text-[#101828]">
          Simplest Logistics Platform
        </h3>
      </div>

      <div className="w-[1200px] flex gap-6 flex-col">
        {FAQData.map((data) => (
          <details
            key={data.id}
            className="group p-8 [&_summary::-webkit-details-marker]:hidden rounded-[10px] "
            style={{
              boxShadow:
                "0px 0px 6px 0px rgba(0, 0, 0, 0.07), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
            }}
          >
            <summary className="flex cursor-pointer items-center justify-between gap-1.5 ">
              <h2 className="text-xl font-medium ">{data.heading}</h2>

              <span className="relative size-6 shrink-0">
                <IoIosArrowDown />
              </span>
            </summary>

            <p className="mt-4 leading-relaxed text-[grey]">{data.detail}</p>
          </details>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
